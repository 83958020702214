*, :before, :after {
  box-sizing: border-box;
}

html {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

.splitting .word, .splitting .char {
  display: inline-block;
}

.splitting .char {
  position: relative;
}

.splitting .char:before, .splitting .char:after {
  content: attr(data-char);
  visibility: hidden;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: inherit;
  position: absolute;
  top: 0;
  left: 0;
}

.splitting {
  --word-center: calc((var(--word-total)  - 1)  / 2);
  --char-center: calc((var(--char-total)  - 1)  / 2);
  --line-center: calc((var(--line-total)  - 1)  / 2);
}

.splitting .word {
  --word-percent: calc(var(--word-index)  / var(--word-total) );
  --line-percent: calc(var(--line-index)  / var(--line-total) );
}

.splitting .char {
  --char-percent: calc(var(--char-index)  / var(--char-total) );
  --char-offset: calc(var(--char-index)  - var(--char-center) );
  --distance: calc( (var(--char-offset)  * var(--char-offset) )  / var(--char-center) );
  --distance-sine: calc(var(--char-offset)  / var(--char-center) );
  --distance-percent: calc((var(--distance)  / var(--char-center) ) );
}

:root {
  --color-primary: #fff;
  --ease: cubic-bezier(.33, 1, .68, 1);
}

@font-face {
  font-family: KWAmorph;
  src: url("KWAmorph-Light.b53422ac.woff") format("woff"), url("KWAmorph-Light.6e361ada.woff2") format("woff2");
}

html, body {
  height: 100%;
}

body {
  color: var(--color-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  font-family: KWAmorph, system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

.container {
  height: 100%;
  transition: padding 250ms var(--ease);
  flex-direction: column;
  padding: 30px 25px;
  display: flex;
}

@media (min-width: 768px) {
  .container {
    padding: 70px;
  }
}

.content {
  opacity: 0;
  position: relative;
}

.content.show {
  opacity: 1;
}

.title {
  width: 100%;
  z-index: 2;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.title svg {
  width: 100%;
  height: auto;
  max-height: 100%;
  max-width: 982px;
  display: block;
}

.title .logo-letters path {
  opacity: 0;
}

.title__button {
  all: unset;
  cursor: pointer;
  pointer-events: none;
  width: 100%;
  display: block;
}

.title__button--active {
  pointer-events: auto;
}

.text {
  width: 100%;
  letter-spacing: -.4px;
  font-size: 40px;
  line-height: 53px;
  position: absolute;
  top: 0;
  left: 0;
}

.text p {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.text .word {
  opacity: 0;
  padding-bottom: 2px;
}

@media (min-width: 768px) {
  .text {
    letter-spacing: -.5px;
    font-size: 50px;
    line-height: 58px;
  }
}

.footer {
  margin-top: auto;
}

.icons {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.icon {
  transition: margin-left 250ms var(--ease), margin-right 250ms var(--ease);
  margin-right: 27px;
}

@media (min-width: 768px) {
  .icon {
    margin-right: 31px;
  }
}

.icon--linkedin {
  margin-right: 26px;
}

.icon--twitter, .icon--linktree {
  margin-right: 22px;
}

@media (min-width: 768px) {
  .icon-twitter {
    margin-right: 25px;
  }

  .icon--linktree {
    margin-right: 29px;
  }

  .icon--linkedin {
    margin-right: 32px;
  }
}

.icon__link {
  transition: transform .3s var(--ease);
  display: block;
}

.icon__link:hover {
  transform: scale(1.07);
}

.icon__link svg {
  transition: width 250ms var(--ease), height 250ms var(--ease);
  height: 24px;
  width: auto;
}

@media (min-width: 560px) {
  .icon__link svg {
    height: 34px;
  }
}

@media (min-width: 768px) {
  .icon__link svg {
    height: 40px;
  }
}

button.icon__link {
  all: unset;
  cursor: pointer;
  transition: transform .3s var(--ease), width 250ms var(--ease);
  display: flex;
}

.wave1, .wave2 {
  opacity: 0;
  transition: opacity .1s linear, transform 0s .1s;
  transform: translateX(-10px);
}

.icon--sound {
  margin-left: auto;
  margin-right: 0;
}

.icon--sound .icon__link {
  width: 21px;
}

@media (min-width: 560px) {
  .icon--sound .icon__link {
    width: 30px;
  }
}

@media (min-width: 768px) {
  .icon--sound .icon__link {
    width: 39px;
  }
}

.icon--sound .icon__link.active .wave1, .icon--sound .icon__link.active .wave2 {
  opacity: 1;
  transition: opacity 100ms linear, transform .3s var(--ease);
  transform: translateX(0);
}

.icon--sound .icon__link.active .wave2 {
  transition-delay: 70ms;
}

.settings {
  z-index: 1000;
  width: 256px;
  position: absolute;
  top: 8px;
  right: 8px;
}

/*# sourceMappingURL=index.5db0661b.css.map */
