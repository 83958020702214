@import "npm:modern-normalize/modern-normalize.css";
@import "npm:splitting/dist/splitting.css";
@import "./style/variables.css";

@custom-media --sm (width >= 560px);
@custom-media --md (width >= 768px);
@custom-media --lg (width >= 1024px);
@custom-media --xl (width >= 1280px);
@custom-media --xxl (width >= 1440px);

@font-face {
  font-family: "KWAmorph";
  src: url("./fonts/KWAmorph-Light.woff") format("woff"),
    url("./fonts/KWAmorph-Light.woff2") format("woff2");
}

html,
body {
  height: 100%;
}

body {
  font-family: "KWAmorph", system-ui, -apple-system, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  background: black;
  color: var(--color-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 30px 25px;
  transition: padding 250ms var(--ease);

  @media (--md) {
    padding: 70px;
  }
}

.content {
  position: relative;
  opacity: 0;
  &.show {
    opacity: 1;
  }
}

.title {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  z-index: 2;
  & svg {
    width: 100%;
    height: auto;
    max-height: 100%;
    max-width: 982px;
    display: block;
  }

  & .logo-letters path {
    opacity: 0;
  }
}

.title__button {
  all: unset;
  cursor: pointer;
  pointer-events: none;
  display: block;
  width: 100%;
}

.title__button--active {
  pointer-events: auto;
}

.text {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 40px;
  line-height: 53px;
  width: 100%;
  letter-spacing: -0.4px;

  & p {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  & .word {
    opacity: 0;
    padding-bottom: 2px;
  }

  @media (--md) {
    font-size: 50px;
    line-height: 58px;
    letter-spacing: -0.5px;
  }
}

.footer {
  margin-top: auto;
}

.icons {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.icon {
  margin-right: 27px;
  transition: margin-left 250ms var(--ease), margin-right 250ms var(--ease);
  @media (--md) {
    margin-right: 31px;
  }
}

.icon--linkedin {
  margin-right: 26px;
}

.icon--twitter,
.icon--linktree {
  margin-right: 22px;
}

@media (--md) {
  .icon-twitter {
    margin-right: 25px;
  }
  .icon--linktree {
    margin-right: 29px;
  }
  .icon--linkedin {
    margin-right: 32px;
  }
}

.icon__link {
  display: block;
  transition: transform 0.3s var(--ease);

  &:hover {
    transform: scale(1.07);
  }

  & svg {
    transition: width 250ms var(--ease), height 250ms var(--ease);
    height: 24px;
    width: auto;
    @media (--sm) {
      height: 34px;
    }
    @media (--md) {
      height: 40px;
    }
  }
}

button.icon__link {
  all: unset;
  cursor: pointer;
  display: flex;
  transition: transform 0.3s var(--ease), width 250ms var(--ease);
}

.wave1,
.wave2 {
  opacity: 0;
  transform: translateX(-10px);
  transition: opacity 100ms linear, transform 0s 0.1s;
}

.icon--sound {
  margin-left: auto;
  margin-right: 0;
  & .icon__link {
    width: 21px;
    @media (--sm) {
      width: 30px;
    }
    @media (--md) {
      width: 39px;
    }

    &.active {
      & .wave1,
      & .wave2 {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 100ms linear, transform 0.3s var(--ease);
      }

      & .wave2 {
        transition-delay: 0.07s;
      }
    }

    /* & .sound-active {
      display: none;
    }
    &.active {
      & .sound-active {
        display: block;
      }
      & .sound {
        display: none;
      }
    } */
  }
}

.settings {
  position: absolute;
  z-index: 1000;
  top: 8px;
  right: 8px;
  width: 256px;
}
